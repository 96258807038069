<template>
  <div class="wet-done-bars-chart mb-3">
    <div class="d-flex align-items-center">
      <div class="flex-fill mb-3" style="min-width:0;">
        <div
          class="nice-select"
          id="bars-chart-type-select"
          :class="selectTypeOpen ? 'open' : ''"
          @click="selectTypeOpen = !selectTypeOpen"
          style="min-width:auto;"
        >
          <div class="current ellipsis">{{currentType}}</div>
          <ul class="list">
            <li
              class="option ellipsis"
              :id="`bars-chart-type-option-${item.value}`"
              :class="type === item.value ? 'selected focus' : ''"
              v-for="(item, id) in historicalSelect.options"
              :key="id"
              @click="type = item.value"
            >{{item.title}}</li>
          </ul>
        </div>
      </div>
      <div class="mb-3 ml-3" v-if="type === availableTypes.weather">
        <div
          class="nice-select"
          id="bars-chart-year-select"
          :class="selectYearOpen ? 'open' : ''"
          @click="selectYearOpen = !selectYearOpen"
          style="min-width:auto;"
        >
          <span class="current">{{year}}</span>
          <ul class="list">
            <li
              class="option"
              :id="`bars-chart-year-${item}`"
              :class="year === item ? 'selected focus' : ''"
              v-for="(item, id) in years"
              :key="id"
              @click="year = item"
            >{{item}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col text-center mb-3">{{title}}</div>
    <Bars
      ref="bars"
      style="position:relative;"
      :options="options"
      :chartData="chartData"
      :loading="loading"
    />
  </div>
</template>
<script>
// import Bars from '@/components/charts/BarsChart.vue';
import done from '@/mixins/done';
import common from '@/mixins/common';
import { mapState } from 'vuex';
import { numToPriceFormat } from '@/common/snippets';

export default {
  mixins: [done, common],
  components: {
    Bars: () => import(/* webpackChunkName: "Bars" */ '@/components/charts/BarsChart.vue'),
  },
  props: {
    threshold: [String, Number],
    thresholdUnit: String,
    fromZero: {
      type: Boolean,
      default: false,
    },
    risk_days: [String, Number],
    weatherType: String,
    dailyPayout: Number,
    currency: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      orderWeatherApiUrl: '/api/order/weather',
      orderPayoutDaysApiUrl: '/api/order/payout_days',
      options: {
        aspectRatio: 16 / 9,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              offset: false,
            },
          ],
        },
      },
      year: 2020,
      type: 'weather',
      availableTypes: {
        weather: 'weather',
        payout: 'payout',
      },
      selectYearOpen: false,
      selectTypeOpen: false,
      apiThresholdData: [],
      apiPayoutDaysData: [],
      loading: false,
      startYear: 2000,
      endYear: 2020,
    };
  },
  computed: {
    ...mapState(['strings', 'lang', 'colors']),
    apiData() {
      // sorted by date
      let apiData = this.sortByDate(this.apiThresholdData);
      // trim null dates
      apiData = apiData.filter((d) => d.value !== null);
      // format to DD-MM-YYYY
      apiData = apiData.map((el) => ({
        ...el,
        date: el.value === null
          ? this.strings?.done?.not_available
          : this.$moment(el.date, 'YYYY-MM-DD').format('DD MMM'),
      }));
      if (this.type !== this.availableTypes.weather) {
        apiData = this.apiPayoutsData.map((el) => ({
          date: el.payout_days === null
            ? this.strings?.done?.not_available
            : el.year,
          value: el.payout,
        }));
      }
      return apiData;
    },
    apiPayoutsData() {
      // sorted by year
      let data = this.apiPayoutDaysData;
      data = data.sort(
        (a, b) => Number.parseInt(a.year, 10) - Number.parseInt(b.year, 10),
      );

      // filter between startYear and endYear
      data = data.filter((d) => d.year >= this.startYear && d.year <= this.endYear);

      // add payout_days_after_risk_days
      data = data.map((el) => ({
        ...el,
        payout: Math.round(Math.max(
          0,
          el.payout_days - Number(this.risk_days),
        ) * this.dailyPayout * 100) / 100,
      }));

      return data;
    },
    years() {
      return Array.from(Array(this.endYear - this.startYear + 1), (v, k) => this.endYear - k);
    },
    chartData() {
      const data = {
        labels: [
          '',
          ...this.apiData.map((el) => el.date),
          '',
        ],
        datasets: [
          {
            label: 'Current threshold',
            backgroundColor: 'transparent',
            borderColor: '#6c757d',
            pointBorderColor: 'transparent',
            data: Array.from(Array(this.apiData.length + 2), () => this.threshold),
            // Changes this dataset to become a line
            type: 'line',
          },
          {
            label: this.strings.commonApp?.diagram?.[this.weatherType]?.label,
            backgroundColor: this.colors.primary,
            offset: false,
            maxBarThickness: '32',
            data: [
              '',
              ...this.apiData.map((el) => el.value),
              '',
            ],
          },
        ],
      };
      if (this.type !== this.availableTypes.weather) {
        data.labels = [
          '',
          ...this.apiData.map((el) => el.date),
          '',
        ];
        data.datasets = [
          {
            label: this.strings.commonApp?.diagram?.payout?.label,
            backgroundColor: this.colors.primary,
            offset: false,
            maxBarThickness: '14',
            data: [
              '',
              ...this.apiData.map((el) => el.value),
              '',
            ],
          },
        ];
      }
      return data;
    },
    historicalSelect() {
      return {
        options: [
          {
            title: this.strings.done?.select_option_historical_weather,
            value: 'weather',
          },
          {
            title: this.strings.done?.select_option_historical_payout,
            value: 'payout',
          },
        ],
      };
    },
    currentType() {
      const type = this.historicalSelect.options.filter((el) => el.value === this.type)[0];
      return type ? type.title : '';
    },
    title() {
      let title = '';
      if (this.type === this.availableTypes.weather) {
        // test data by valid DD MMM
        const validDateData = this.apiData.filter((d) => /^\d{1,2}\s.{2,10}$/g.test(d.date));
        const min = this.$moment(Math.min(
          ...validDateData.map((el) => this.$moment(el.date, 'DD MMM', true).year(this.year)),
        )).format('D. MMM YYYY');
        const max = this.$moment(Math.max(
          ...validDateData.map((el) => this.$moment(el.date, 'DD MMM', true).year(this.year)),
        )).format('D. MMM YYYY');
        title = this.format(this.strings.done?.historical_dates_title[this.weatherType], `${min} - ${max}`);
      } else {
        const min = Math.min(...this.apiPayoutsData.map((el) => el.year));
        const max = Math.max(...this.apiPayoutsData.map((el) => el.year));
        title = this.format(this.strings.done?.historical_dates_title_payout, min, max);
      }
      return title;
    },
  },
  watch: {
    year: {
      handler() {
        this.getHistorical();
      },
    },
    threshold: {
      handler() {
        this.updGraphData();
      },
    },
    risk_days: {
      handler() {
        this.updMinMax();
        this.updGraphData(true);
      },
    },
    type: {
      immediate: true,
      handler() {
        this.updGraphData();
      },
    },
    dailyPayout: {
      handler() {
        this.updGraphData(true);
      },
    },
  },
  methods: {
    sortByDate(arr) {
      // sort array by date
      return arr.sort((a, b) => this.$moment(a.date, 'YYYY-MM-DD').diff(this.$moment(b.date, 'YYYY-MM-DD')));
    },
    updMinMax() {
      if (this.$isDev) console.log('upd minmax', this.type);
      let min = Math.min(...(this.apiData).map((el) => el.value));
      let max = Math.max(...(this.apiData).map((el) => el.value));
      if (this.type === this.availableTypes.payout) {
        min = Math.floor(min * 1.1);
        max = Math.ceil(max * 1.1);
      } else {
        min = Math.floor(Math.min(min, Number.parseFloat(this.threshold)) * 1.1);
        max = Math.ceil(Math.max(max, Number.parseFloat(this.threshold)) * 1.1);
      }
      this.updChartOptions({
        scales: {
          yAxes: [
            {
              ticks: {
                min: this.fromZero
                  ? 0
                  : min,
                max,
                precision: this.type === this.availableTypes.payout ? 0 : 1,
              },
            },
          ],
        },
      });
    },
    /** y axes legend lable */
    setYLabel(labelString) {
      this.updChartOptions({
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: !!labelString,
                labelString,
              },
            },
          ],
        },
      });
    },
    /** y axes labels */
    setYAxeLabel(callback) {
      this.updChartOptions({
        scales: {
          yAxes: [{
            ticks: {
              // Include a dollar sign in the ticks
              callback,
            },
          }],
        },
      });
    },
    /** tooltip text */
    setTooltipText(callback) {
      this.updChartOptions({
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              let label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                label += ': ';
              }
              label += callback(tooltipItem.yLabel);
              return label;
            },
          },
        },
      });
    },
    updChartOptions(newOptions) {
      const options = { ...this.options };
      this.$lodash.merge(options, newOptions);
      this.options = options;
      if (this.$refs.bars) this.$refs.bars.update();
    },
    async getHistorical() {
      // call API
      this.loading = true;
      await this.callApi({ url: `${this.orderWeatherApiUrl}/?year=${this.year}` })
        .then((res) => {
          this.apiThresholdData = res.data;
        })
        .finally(() => {
          this.loading = false;
          // upd chart settings
          this.updMinMax();
        });
    },
    async getPayoutDays() {
      // call historical payout days api
      this.loading = true;
      await this.callApi(this.orderPayoutDaysApiUrl)
        .then((res) => {
          this.apiPayoutDaysData = this.arrayTrim(res.data[0][Object.keys(res.data[0])[0]] || [],
            (el) => el.payout_days !== null);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    arrayTrim(arr, test) {
      return arr.slice(
        arr.findIndex((el) => test(el)),
        arr.length - [...arr].reverse().findIndex((el) => test(el)),
      );
    },
    async updGraphData(onlyPayout) {
      // update the chart depending on its type
      if (this.type === this.availableTypes.weather) {
        if (!onlyPayout) {
          this.setYLabel(this.thresholdUnit);
          this.setYAxeLabel((value) => value);
          this.setTooltipText((value) => `${value} ${this.thresholdUnit}`);
          await this.getHistorical();
        }
      } else {
        this.setYLabel(this.currency);
        this.setYAxeLabel((value) => this.numToPriceFormat(value, this.lang));
        this.setTooltipText((value) => `${this.numToPriceFormat(value, this.lang)} ${this.currency}`);
        await this.getPayoutDays();
      }
      this.updMinMax();
    },
    numToPriceFormat,
  },
};
</script>
<style lang="scss">
</style>
